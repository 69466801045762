* {
    margin: 0;
    padding: 0;
    font-family: 'Outfit';
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    background-color: #f3f3f3 !important;
    font-family: Outfit !important;
}

.css-1v5u2yv {
    padding: .5rem 0rem 0rem 0rem !important;
    padding-top: 0 !important;
}

.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    background-color: #210720 !important;
    /* height: 76px; */
}

.ck-editor {
    color: black;
}

.col_custom {
    background-color: #f3f3f3;
}

.main_box_dashboard {
    display: none !important;
}

.table-responsive {
    overflow-x: clip !important;
}

.table_middle_text_box {
    transform: translate(0px, 15px);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.seo-note-box {
    margin-top: 1rem;
}

.seo-note-box .seo-note-title {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 500;
}

.seo-note-box .seo-note-title span {
    font-weight: 600;
}

.h_pop_up_img_box {
    height: 300px;
    width: 100%;
    margin-top: 2rem;
}

.h_pop_up_img_box img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.home_pop_up_form_g {
    border: 1px solid #d9d9d973;
    padding: 1rem;
}

.h_pop_up_main_title {
    font-size: 17px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.blog_desc .ck.ck-editor {
    position: relative;
    width: 100% !important;
}

.NoteBox {
    margin-top: 1.5rem;
    text-align: center;
}

.NoteBox p {
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.NoteBox p span {
    color: red;
}

.table-product-name {
    font-size: 13px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    width: 30vw;
    overflow: hidden;
    text-align: start;
}

.table-input {
    width: 10vw !important;
}